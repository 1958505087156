import { createSlice } from '@reduxjs/toolkit'

export const librarySlice = createSlice({
  name: 'library',
  initialState: {
    media: null,
  },
  reducers: {
    updateMedia: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.media = action.payload;
    }
  },
})


export const { updateMedia } = librarySlice.actions

export default librarySlice.reducer
