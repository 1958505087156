import * as React from 'react';
import Box from '@mui/material/Box';
import Libraries from './Libraries';



export default function Manager() {
  return (
    <Box sx={{ overflow: 'hidden' }} className="Video">
      <Libraries loading={true} />
    </Box>
  );
}
