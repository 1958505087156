import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { AppBar, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Toolbar, Typography } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { useDispatch } from 'react-redux'
import { Container } from '@mui/system';
import { Card, CardHeader, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import WarningIcon from '@mui/icons-material/Warning';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
const actions = [
  { icon: <FolderOutlinedIcon />, name: 'Create library', type: 'create-library' }
];


function LibrarySpeedDial(props) {
  const {onSelect} = props;
  return (
    <Box sx={{ height: 100, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="Selection action"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => onSelect(action.type)}
            />
        ))}
      </SpeedDial>
    </Box>
  );
}


function LibraryFolder(props){

  const {name, id} = props;
  const dispatch = useDispatch()

  return (
    <Box className="video-folder">
        <Card variant="elevation">
            <CardHeader title={name} className="header" />
            <CardContent className="lib-content"> 
              <IconButton  color="default" aria-label="Open media folder" href={`/video/library/${id}`} >
                  <FolderOutlinedIcon fontSize='large' className="ico"/>
              </IconButton>
            </CardContent>
          </Card>
    </Box>
  )
}


function PackageProperties(props){

  const {title, subtitle, when, type} = props;

  return (
    <Card variant="outlined" className="video-statistics">
      <CardHeader title={title} subheader={subtitle} className="header" />
        <CardContent>
          <p className="allowance">
              {type} <span className="of"> paid {when}</span>
          </p>
        </CardContent>
    </Card>
  )
}



function LibProperties(props){

  const {title, subtitle, allowance, used, what} = props;

  return (
    <Card variant="outlined" className="video-statistics">
      <CardHeader title={title} subheader={subtitle} className="header" />
        <CardContent>
          <p className="allowance">
              {used}<span className="of"> / {allowance} {what}</span>
          </p>
        </CardContent>
    </Card>
  )
}

function CreateLibraryDialog( props )
{
    const {open, onClose, onRefresh} = props;
    const [loading, setLoading] = React.useState(false);
    const [name, setName] = React.useState('');
    const [error, setError] = React.useState('');
    const onSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      var chain = '';
      chain += '&name=' + encodeURI(name);

      fetch(`/video/add-media-library/`, {
        method: "POST",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit                            
        body: window.configuration.security_name + '=' + window.configuration.security_value + chain ,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
      })
      .then(res => {
        return res.json();
      })
      .then(async(response) => {
        //console.log(data);
        if(response.status === 'OK')
        {
          await onRefresh();
          onClose(false)
          setLoading(false)
        }
        else
        {
            setLoading(false)
            setError('Failed to add library. Reason: ' + response.reason);
        }
      }).catch((e)=>{
        setLoading(false)
        setError('Network error. Please reload and try again. ')
      });  
    }

    return (
      <Dialog open={open} fullWidth={true} maxWidth="sm">
            <form onSubmit={onSubmit}>
              <DialogTitle>Create library</DialogTitle>
              <DialogContent>

                {
                  !!!loading && !!!error &&
                  <Grid container  direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} className="mt-2">
                      <Grid item xs={12} className="confirm">
                          <DialogContentText>
                              Please enter the name of the library
                          </DialogContentText>
                          <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Name"
                          type="text"
                          fullWidth
                          variant="standard"
                          value={name}
                          required
                          inputProps={{pattern:"[a-zA-Z0-9]{1,250}"}}
                          onChange={(e) => {setName(e.target.value)}}
                        />                        
                      </Grid>
                  </Grid>
                }
                {
                  !!loading &&(
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{ margin: 'auto' }} size={100} />
                  </Box>                  
                  )
                }
                {
                  !!error &&(
                  <Box sx={{ display: 'flex' }}>
                    <div className="alert"><span><WarningIcon /></span>{error}</div>
                  </Box>                  
                  )                
                }                
              </DialogContent>
              <DialogActions>
                <Button onClick={() => onClose(false)} disabled={!!loading}>Cancel</Button>
                <Button onClick={() => {}} disabled={!!loading || !!error} type="submit">Create</Button>
              </DialogActions>
            </form>

        </Dialog>
    )  
}


export default function Libraries() {
  const [addlib, onAddLib] = React.useState(false);
  const [libraries, onSetLibrary] = React.useState(window.configuration?.package?.libraries || []);
  const refreshLibraries = () => {

      try
      {
          new Promise((resolve, reject) => {

              fetch("/video/get-media-libraries", {
                  method: "GET",
                  mode: 'same-origin', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'include', // include, *same-origin, omit                            
                  //body: window.configuration.security_name + '=' + window.configuration.security_value ,
                  headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "X-Requested-With": 'XMLHttpRequest',
                      "X_REQUESTED_WITH": 'xmlhttprequest'
                  }
              })
              .then(res => {
                  return res.json();
              })
              .then(async(response) => {
                  //console.log(data);
                  if(response.status === 'OK')
                  {
                      resolve(response.libraries);
                  }
                  else
                  {
                      reject('Failed to delete folder. Reason: ' + response.reason);
                  }
              }
              ).catch((e)=>{
                reject('Network error. Please reload and try again. ', e)
              }); 

          }).then( libraries => {
              console.error('fetched library response', libraries);
              onSetLibrary(libraries || [])
          }).catch(error => {
            console.error('Failed to load library.', error)
            onSetLibrary([])    
          });
      }
      catch(e)
      {
        console.error('Failed to load library.', e)
        onSetLibrary([])
      }

  }



  return (
    <Container>
      <Box sx={{pb: 2, pt:2}} className="navigation">
          <AppBar position="sticky" className="nav">
            <Toolbar>
                  <Typography variant="h2" className="app-title" component="div" sx={{ flexGrow: 1 }}>
                  Video Manager
                  </Typography>
                  <Button variant="outlined" color="primary"  onClick={() => onAddLib(true)} startIcon={<LibraryAddIcon/>}>
                    Add
                  </Button>   
            </Toolbar>
          </AppBar>
      </Box>
  

      <Box  className="body">
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>

              
              <Grid item xs={12} md={3}>
                  <PackageProperties {...window.configuration.package.platform}/>
              </Grid>              
              {
                  window.configuration.package.properties.map( (p) => (
                    <Grid item xs={12}  md={3} key={uuidv4()}>
                        <LibProperties {...p} />
                    </Grid>          
                  ))
              }
              <Grid item xs={12} className="section">
                <h2 className='title'>Libraries</h2>
                <p className="info">Libraries provide you with a logical way to manage and file your videos. All videos that you upload must be associatd to a library.</p>
              </Grid>              

              <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                  {
                      libraries?.map( (l) =>(
                              <Grid item xs={12} md={2} key={l.id}>
                                  <LibraryFolder {...l}/>
                              </Grid>
                      ))   
                  }
                  </Grid>
              </Grid>
        </Grid>
      </Box>
      {
        addlib &&
        <CreateLibraryDialog open={true} onClose={onAddLib} onRefresh={refreshLibraries}/>
      }
    </Container>
  );
}
