import { createSlice } from '@reduxjs/toolkit'

export const playSlice = createSlice({
  name: 'play',
  initialState: {
    media: {
      id: '',
      src: '',
      videosrc: '',
      title: '',
      channel: '',
      views: '',
      createdAt: '',      
    },
  },
  reducers: {
    updatePlay: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.media = action.payload;
    }
  },
})


export const { updatePlay } = playSlice.actions

export default playSlice.reducer
