import React from 'react';
import videojs from 'video.js';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

// This imports the functional component from the previous sample.
import VideoPlayer from './VideoPlayer.js'


const Play = (props) => {
  const {src} = props;
  const playerRef = React.useRef(null);
  const navigate = useNavigate();
  const file = useSelector((state) => {
    console.log("Library state", state);
    return state.play.media;
  });

  React.useEffect(()=>{
    if(src === '')
    {
      navigate('/video')
    }
  }, [src])

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: src,
        type: 'application/x-mpegURL',
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });


  };


  return (
    <>
      <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
    </>
  )
};

export default Play;