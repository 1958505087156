import './App.css';
import Manager from './Manager';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"; 
import Library from './Library';

import { Provider } from 'react-redux'
import store from './store'



function App() {
  return (
    <div className="Video">
      <Provider store={store}>
        <Router>
            <Routes>
              <Route path="/video/library/*" element={<Library />}>
              </Route>
              <Route path="/video" element={<Manager />}>
              </Route>           
            </Routes>
        </Router>
      </Provider>
    </div>
  );
}



export default App;
